@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .section {
        @apply bg-blue-50 dark:bg-blue-900 flex justify-center;
    }
    .section-content {
        @apply p-4 md:p-7 space-y-5 max-w-screen-2xl w-full;
    }

    .section-header {
        @apply text-3xl font-semibold w-auto inline-block bg-gradient-to-br from-blue-600 to-blue-800 dark:from-white dark:to-blue-50 text-transparent bg-clip-text drop-shadow;
    }

    .section-subheader {
        @apply text-xl font-semibold w-auto inline-block bg-gradient-to-br from-blue-500 to-blue-700 dark:from-white dark:to-blue-50 text-transparent bg-clip-text drop-shadow;
    }

    .section-text {
        @apply text-blue-900 dark:text-blue-200 text-opacity-95;
    }
}

html {
    scroll-behavior: smooth;
}